import logo from '../logo.svg';
import React, { lazy, Suspense } from 'react';
import '../App.css';

import {
  HashRouter,
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";


const Header = React.lazy(() => import("../Templates/Layouts/Header"));
const Footer = React.lazy(() => import("../Templates/Layouts/Footer"));
const Home = React.lazy(() => import("../Templates/HomePage/Home"));
const Page404 = React.lazy(() => import("../Templates/Layouts/Page404"));
const Login = React.lazy(() => import("../Templates/Forms/Login"));
const Signup = React.lazy(() => import("../Templates/Forms/Signup"));
const Forgot = React.lazy(() => import("../Templates/Forms/Forgot"));
const Verify = React.lazy(() => import("../Templates/Forms/Verify"));
const Categories = React.lazy(() => import("../Templates/Categories"));
const Product = React.lazy(() => import("../Templates/ProductDetails"));

const Products = React.lazy(() => import("../Templates/Products"));
const Faq = React.lazy(() => import("../Templates/Faq"));
const ContactUs = React.lazy(() => import("../Templates/ContactUs"));
const PrivacyPolicy = React.lazy(() => import("../Templates/PrivacyPolicy"));
const TermsOfService = React.lazy(() => import("../Templates/TermsOfService"));
const ShippingHandling = React.lazy(() => import("../Templates/ShippingHandling"));
const HelpSupport = React.lazy(() => import("../Templates/Help"));



function AuthStack() {
  return (
    <HashRouter>
      <Suspense
        fallback={
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center', marginTop: window.innerHeight / 2.5 }}>
            <div className="lds-facebook"></div>
          </div>
        }
      >
        {/* <Header /> */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/forgot" element={<Forgot />} />
          <Route exact path="/verify/:id" element={<Verify />} />
          <Route exact path="/category/:id" element={<Categories />} />
          <Route exact path="/products" element={<Products />} />
          <Route exact path="/sortby/:price" element={<Products />} />
          <Route exact path="/product/:id" element={<Product />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/terms-of-service" element={<TermsOfService />} />
          <Route exact path="/shipping-handing" element={<ShippingHandling />} />
          <Route exact path="/Help-&-Support" element={<HelpSupport />} />

          <Route path="*" element={<Login />} />
        </Routes>
        {/* <Footer /> */}
      </Suspense>
    </HashRouter>
  );
}

export default AuthStack;
