import React, { useContext } from 'react'
import AppStack from './AppStack'
import { AuthContext } from './AuthProvider'
import AuthStack from './AuthStack'


function Routes() {

    const { userToken } = useContext(AuthContext)
    console.log(userToken)
    return (
        <>
            {userToken ? <AppStack /> : <AuthStack />}
        </>
    )
}

export default Routes