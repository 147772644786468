import { Animated } from "react-animated-css";
import "../css/style.css"

export const ErrorMsg = ({ msg, setMsg }) => (
    msg !== '' ?
        setTimeout(
            () => {
                // window.location.reload()
                setMsg()
            },
            3500
        ) &&
        <Animated animationIn="slideInDown" animationOut="zoomOut" isVisible={true} animationInDuration={400}>
            <div className='errorMsgContainer' style={{ width: '60%' }}>
                <span className='errorMsgAlert'>{msg}</span>
                <i class="bi bi-x-lg clearMsg" onClick={() => {
                    setMsg()
                    // window.location.reload()
                }}></i>
            </div>
        </Animated>
        : null
)

export function SuccessMsg({ msg, setMsg, autoClose }) {
    return (
        msg !== '' ?
            setTimeout(
                () => {
                    if (autoClose !== false) setMsg()
                },
                3500
            ) &&
            <Animated animationIn="slideInDown" animationOut="zoomOut" isVisible={true} animationInDuration={400}>
                <div className='successMsgContainer'>
                    {/* <span class="material-icons msgSuccessSign">
                        check_circle
                    </span> */}
                    <i class="bi bi-check-circle msgSuccessSign"></i>
                    <span className='successMsg'>{msg}</span>
                    <i class="bi bi-x-lg clearMsg" onClick={() => {
                        setMsg()
                        // window.location.reload()
                    }}></i>
                </div>
            </Animated>
            : null
    )
}